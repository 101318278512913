import { makeStyles } from '@material-ui/styles';
import IntroPopup from 'components/popup/intro';
import InternalTool from './internalTool';
import CustomStyling from './customStyling';
import dynamic from 'next/dynamic';
import GeoIPPopup from './geoipPopup';

const ZendeskChat = dynamic(() => import('./chat'), { ssr: false });

const useStyles = makeStyles(
	{
		wrapper: {}
	},
	{ name: 'PopupWrapper' }
);

export default function PopupWrapper({}) {
	const classes = useStyles();

	return (
		<>
			<ZendeskChat />
			<InternalTool />
			<CustomStyling />
			<GeoIPPopup />
			<IntroPopup />
		</>
	);
}
