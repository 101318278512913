import { readState, saveState } from 'history/lib/DOMStateStorage';

export default class StateStorage {
    read(location, key) {
        return readState(this.getStateKey(location, key));
    }

    save(location, key, value) {
        saveState(this.getStateKey(location, key), value);
    }

    getStateKey(location, key) {
        const locationKey = location ?? '_default';
        const stateKeyBase = `${locationKey}`;

        return key == null ? stateKeyBase : `${stateKeyBase}|${key}`;
    }
}