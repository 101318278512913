import React, {useEffect, useState} from 'react';
import stateStorage from "./stateStorage";
import {useRouter} from 'next/router';
import ScrollContext from "./scrollContext";

const DEBOUNCE_TIMEOUT = 150;

export default function ScrollProvider({children}) {
	const router = useRouter();
	const [state, setState] = useState({});
	const [isPop, setIsPop] = useState(false);
	const [scrollToNext, setScrollToNext] = useState(false);

	const storage = new stateStorage();
	let timeout = setTimeout(() => {});
	let url = router.asPath;

	function onPageLoad() {
		const hashtag = url.indexOf('#') > 0 ? url.split('#')[1] : false;

		if(hashtag) {
			window.requestAnimationFrame(() => {
				scrollToElement(hashtag);
			});
		}

		if(scrollToNext) {
			scrollTo(scrollToNext);
			setScrollToNext(false);
		}
	}

	function onPushState() {
		setIsPop(false);

		const hashtag = window.location.href.indexOf('#') > 0 ? window.location.href.split('#')[1] : false;

		if(hashtag) {
			window.requestAnimationFrame(() => {
				scrollToElement(hashtag);
			});
		}
	}

	function scrollToElement(id) {
		const element = document.getElementById(id);

		if (element) {
			const pos = element.getBoundingClientRect().top + window.scrollY;
			scrollTo(pos);
		}
	}

	function scrollTo(position) {
		window.requestAnimationFrame(() => {
			window.scrollTo(0, position);

			setTimeout(() => {
				window.scrollTo(0, position);
			}, 200);
		});
	}

	function onScroll() {
		timeout = setTimeout(() => {
			let scrollToTop = Math.abs(document.documentElement.getBoundingClientRect().top || document.body.getBoundingClientRect().top);

			storage.save(
				getLocation(),
				'_np',
				scrollToTop
			);
		}, DEBOUNCE_TIMEOUT);
	}

	function onPopState() {
		let pos = storage.read(getLocation(), '_np');
		setScrollToNext(pos);

		setIsPop(true);
	}

	function resetPopState() {
		setIsPop(false);
	}

	function onReplaceState() {}

	function getLocation() {
		return btoa(location.href);
	}

	function setNewUrl(newUrl) {
		url = newUrl;
	}

	function setCache(key, value) {
		state[key] = value;

		setState(state);
	}

	function getCache(key) {
		let cache = state[key];

		return cache || false;
	}

	useEffect(() => {
		router.events.on('routeChangeStart', resetPopState);
		router.events.on('routeChangeComplete', setNewUrl);

		window.addEventListener('scroll', onScroll);
		window.addEventListener('popstate', onPopState);
		window.addEventListener('replacestate', onReplaceState);
		window.addEventListener('pageLoaded', onPageLoad);
		window.addEventListener('hashchange', onPushState);
		window.addEventListener('pushstate', onPushState);

		return () => {
			router.events.off('routeChangeStart', resetPopState);
			router.events.off('routeChangeComplete', setNewUrl);

			window.removeEventListener('scroll', onScroll);
			window.removeEventListener('popstate', onPopState);
			window.removeEventListener('replacestate', onReplaceState);
			window.removeEventListener('pageLoaded', onPageLoad);
			window.removeEventListener('hashchange', onPushState);
			window.removeEventListener('pushstate', onPushState);
		}
	}, [router.asPath]);

	return <ScrollContext.Provider value={{url, setCache, getCache, isPopState: isPop}}>{children}</ScrollContext.Provider>;
}