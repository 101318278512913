import React, { createContext, useState } from 'react';

// Create the context
export const ImageGalleryIndexContext = createContext();

// Create the provider component
export const ImageGalleryIndexProvider = ({ children }) => {
	const [imageGalleryIndex, setImageGalleryIndex] = useState(0); // Storing an integer

	return (
		<ImageGalleryIndexContext.Provider value={{ imageGalleryIndex, setImageGalleryIndex }}>
			{children}
		</ImageGalleryIndexContext.Provider>
	);
};