import * as Sentry from '@sentry/nextjs';

export default function reportError(context) {
    let errorDetails;

    if (!context?.error instanceof Error) {
        // Fallback for non-Error objects or missing error in context
        errorDetails = {
            name: 'ApplicationErrorReport: UnknownError',
            message: context?.error ? String(context.error) : 'No error provided',
            stack: context?.error?.stack || 'No stack trace available',
        };
        console.error('[ReportError] Invalid error object:', context);
    }

    const stack = errorDetails?.stack || context?.error?.stack;

    if (stack?.includes('bat.bing.com')) {
        console.error('[ReportError] aborted stack contains Bing:', context);
        return;
    }

    if (!errorDetails) {
        console.error('[ReportError] aborted undefined Error:', context);
        return;
    }

    // Report the error to Sentry
    Sentry.captureException(errorDetails, {
        level: 'critical',
        extra: {
            argument: context,
        }
    });

    // Log additional information in the console if in development mode
    if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'Development') {
        console.error('Error Context:', context);
    }
}
