import {makeStyles} from "@material-ui/styles";
import {useContext, useEffect, useState} from "react";
import {SessionContext} from "../providers/session";
import {Button, Modal} from "@material-ui/core";
import Cookies from 'js-cookie';
import dynamic from "next/dynamic";
import countryMapping from './countryMapping.json';
import {Check, Close} from "@material-ui/icons";
const Flag = dynamic(() => import("../misc/flag"));
import {useTranslation} from "../../tools/i18n";
import {useRouter} from "next/router";
import Link from "next/link";
import urlCorrection from "../../tools/urlCorrection";
import { TrackingContext } from "../providers/tracking";
import theme from 'components/theme'

const useStyles = makeStyles({
	wrapper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 400,
        maxHeight: 'calc(100vh - 40px)',
        padding: '30px',
        overflow: 'auto',
        width: '100%',
        backgroundColor: '#ffffff',
        borderRadius: 5,
        outline: 'none',
        transform: 'translate(-50%, -50%)'
	},
    closeBtn: {
        position: 'absolute',
        top: 5,
        right: 5,
        minWidth: 0,
        padding: 6,
        color: '#888'
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    flag: {
        marginBottom: 20
    },
    title: {
	    margin: '0 0 20px',
	    fontSize: 18,
        fontWeight: 300,
        textTransform: 'uppercase',
        textAlign: 'center'
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0 10px',
        padding: '30px 20px',
        textAlign: 'left',
        backgroundColor: '#f2f2f2',
        '& p': {
            margin: '4px 0 0',
            fontSize: 14,
            fontWeight: 300,
            lineHeight: 1.7,
            color: theme.palette.primary.lowlight,
            '& a': {
                color: 'inherit'
            }
        },
        '& h3': {
            margin: 0,
            fontSize: 14,
            fontWeight: 600,
            lineHeight: 1.4,
            textTransform: 'uppercase'
        },
        '& ul': {
            width: '100%',
            padding: 0,
            margin: '16px 0 0',
            '& li': {
                position: 'relative',
                display: 'flex',
                fontSize: 14,
                fontWeight: 300,
                lineHeight: 1.7,
                color: theme.palette.primary.lowlight,
                '&.minimize': {
                    position: 'relative',
                    maxHeight: 48,
                    overflow: 'hidden',
                },
                '& svg': {
                    marginTop: 4,
                    marginRight: 6,
                    fontSize: 16
                }
            }
        }
    },
    showMore: {
	    position: 'absolute',
        right: 15,
        bottom: 1,
        padding: '0 0 0 2px',
        backgroundColor: '#f2f2f2',
        '& span': {
	        marginLeft: 5,
            fontWeight: 500,
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    },
    actions: {
        marginTop: 20
    },
    redirect: {
	    padding: '20px 15px',
	    width: '100%',
	    backgroundColor: "#212121",
        textTransform: 'none',
        fontWeight: 400,
        color: "white",
        borderRadius: 0,
        '&:hover': {
	        backgroundColor: '#555',
        }
    },
    stay: {
	    display: 'block',
	    marginTop: 20,
        fontSize: 14,
        fontWeight: 300,
        textAlign: 'center',
        lineHeight: 1,
	    opacity: 0.8,
        textDecoration: 'underline'
    }
}, {name: "GeoIPPopup"});

export default function GeoIPPopup() {
    const classes = useStyles();
    const router = useRouter();
    const {t} = useTranslation('popup');
    const {actions: trackingActions} = useContext(TrackingContext);
    const [visitorCountry, setVisitorCountry] = useState(false);
    const [primaryWebsite, setPrimaryWebsite] = useState(false);
    const [maximize, setMaximize] = useState(false);
    const [open, setOpen] = useState(false);
    const euSalesChannelId = '369471b7efe746eab4527b3a0d7e49af';
    const {
        token,
        config,
        geoIpCountry,
        countries,
        salesChannel
    } = useContext(SessionContext);

    useEffect(() => {
        if(token && !Cookies.get('GeoIPPopup') && (document?.referrer === '' || document?.referrer?.indexOf('newport') < 0) && geoIpCountry) {
            try {
                setVisitorCountry(geoIpCountry);
                Cookies.set('GeoIPPopup', true, { expires: 30 });
            } catch (e) {
                console.log(e);
            }
        }
    }, [token]);

    useEffect(() => {
        if(!visitorCountry || !token) {
            return;
        }

        let foundCountryInCurrentSalesChannel = false;

        countries.forEach((country) => {
            if(country.iso === visitorCountry) {
                foundCountryInCurrentSalesChannel = true;
            }
        });

        if(!foundCountryInCurrentSalesChannel || (foundCountryInCurrentSalesChannel && salesChannel.id === euSalesChannelId)) {
            let websites = Object.keys(countryMapping.websites);
            let availableWebsites = websites.filter((website) => {
                return countryMapping.websites[website].countries.indexOf(visitorCountry) >= 0;
            });

            if(availableWebsites.length <= 0) {
                setPrimaryWebsite(countryMapping.websites['global']);
                return;
            }

            availableWebsites.sort((a, b) => {
                if(a === 'eu') {
                    return 1;
                } else if (b === 'eu') {
                    return -1;
                } else  {
                    return 0;
                }
            });

            let primaryWebsite = countryMapping.websites[availableWebsites[0]];
            setPrimaryWebsite(primaryWebsite);
        }
    }, [token, visitorCountry]);

    useEffect(() => {
        if(primaryWebsite && router.basePath.indexOf(primaryWebsite.url) < 0) {
            setTimeout(() => {
                setOpen(true);
            }, 2000);
        }
    }, [primaryWebsite]);

    function handleClose(e, reason) {
        if(typeof reason === 'undefined') {
            setOpen(false);

            trackingActions.click({type: 'geo-ip', text: 'close', identifier: urlCorrection(router.asPath)});
        }
    }

    function handleShowAll() {
        setMaximize(true);
    }

    function getCountryName(countryCode) {
        return countryMapping.countries[countryCode] || countryCode;
    }

    function isWorldwide() {
        return primaryWebsite?.url?.includes('newportcollection.com');
    }

    function getAllDeliveryCountries() {
        if(!primaryWebsite) {
            return '';
        }

        let countries = primaryWebsite.countries.map((country) => {
            return countryMapping.countries[country];
        }).filter((country, index) => {
            if(index < 30) {
                return country
            } else {
                return false;
            }
        });

        return countries.join(', ')
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            disableScrollLock={true}
        >
            <div className={classes.wrapper}>
                {false ? <Button className={classes.closeBtn} onClick={handleClose}><Close/></Button> : ''}
                <div className={classes.header}>
                    <Flag code={visitorCountry} className={classes.flag}/>
                    <h2 className={classes.title}
                        dangerouslySetInnerHTML={{ __html: t('geoip-info-title', { country: getCountryName(visitorCountry) }) }}></h2>
                </div>
                <div className={classes.actions}>
                    <Link href={primaryWebsite.url} onClick={(e) => {
                        handleClose(e);
                        trackingActions.click({
                            type: 'geo-ip',
                            text: 'redirect',
                            identifier: urlCorrection(primaryWebsite.url)
                        });
                    }}><Button
                        className={classes.redirect}>{t('geoip-goto', { website: primaryWebsite.title })}</Button></Link>
                </div>
                <div className={classes.info}>
                    <h3 dangerouslySetInnerHTML={{__html: t('geoip-title', { country: getCountryName(visitorCountry) })}}></h3>
                    <p dangerouslySetInnerHTML={{
                        __html: t('geoip-info-text', {
                            country: getCountryName(visitorCountry),
                            url: primaryWebsite.url,
                            website: primaryWebsite.title
                        })
                    }}></p>
                    <ul>
                        <li className={!maximize ? 'minimize' : ''}>
                            <Check/>
                            {t('geoip-info-list-1')}
                            {!maximize && primaryWebsite && primaryWebsite?.url?.indexOf('.eu') > 0 ?
                                <span className={classes.showMore}
                                      onClick={handleShowAll}>...<span>{t('country-selector-show-all')}</span></span> : ''}
                        </li>
                        <li>
                            <Check/>
                            {t('geoip-info-list-2')}
                        </li>
                        <li>
                            <Check/>
                            {isWorldwide(primaryWebsite) ? t('geoip-info-list-3-worldwide') : t('geoip-info-list-3')}
                        </li>
                    </ul>
                </div>
                <div className={classes.actions}>
                    <span className={classes.stay} onClick={handleClose}
                          style={{ cursor: 'pointer', marginTop: 0 }}>{t('geoip-stay')}</span>
                </div>
            </div>
        </Modal>
    );
}
