// Import Sentry
import * as Sentry from '@sentry/browser';

export default function setupSentryFetch() {
    if (window.fetch === undefined) {
        // Save the original fetch method
        const originalFetch = window.fetch;

        // Override the global fetch
        window.fetch = async function setupSentryFetch(...args) {
            try {
                Sentry.setContext('window.fetch check the object for url', {
                    url: args[0],
                    method: args[0].method,
                });

                // Make the fetch request
                const response = await originalFetch(...args);
                if (!response.ok) {
                    console.error(
                        '[Failed fetch][NOT OK] window.fetch check the object for url',
                        args[0],
                        args[1]?.method || 'GET',
                        `Response: ${response.status}`,
                    );
                }

                return response;
            } catch (error) {
                if (error.name === 'TypeError') {
                    // Capture the fetch error in Sentry
                    console.error(
                        '[Failed fetch] window.fetch check the object for url',
                        args[0],
                        args[1]?.method || 'GET',
                        error
                    );
                    // Re-throw the error so it behaves as normal for the app
                    let newError = {
                        ...error,
                    };
                    newError.message = `[Failed Fetch] TypeError || ${args[0]} ${args[1]?.method}`;
                    newError.stack = error.stack;
                    newError.cause = 'Uncaught exception handling of fetch';
                    newError.name = 'FailedFetchWindow';
                    throw newError;
                }
                throw error;
            }
        };
    }
}
